import axios from "axios";

const backendAppUrl = process.env.REACT_APP_BACKEND_API_URL;

const GoogleLogout = ({ onLogout }) => {
    const handleLogout = () => {
        // Send a request to the backend to clear the cookie
        axios
            .post(backendAppUrl + "/auth/logout", {}, { withCredentials: true })
            .then(() => {
                // Clear the cookie on the frontend as well
                document.cookie = "access_token=; Max-Age=0; path=/";
                window.google.accounts.id.disableAutoSelect();
                console.log("User signed out successfully");
                onLogout(); // Call the logout handler passed as prop
            })
            .catch((err) => {
                console.error("Logout error:", err.response ? err.response.data : err);
            });
    };

    return <button onClick={handleLogout}>Sign Out</button>;
};

export default GoogleLogout;
