import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import GoogleLogin from './GoogleLogin';
import GoogleLogout from './GoogleLogout';
import Cookies from 'js-cookie';
import { useAuth } from '../context/AuthContext';

function Navbar() {
    const { isLoggedIn, login, logout } = useAuth();
    const navigate = useNavigate();
    const [showTooltip, setShowTooltip] = useState(false);

    useEffect(() => {
        const token = Cookies.get('access_token');
        if (token) {
            login();
        }
    }, [login]);

    const handleGoogleLogin = (userData) => {
        console.log("User authenticated successfully:", userData);
        login();
    };

    const handleGoogleLogout = () => {
        logout();
    };

    const handleMyCollectionsClick = (e) => {
        if (!isLoggedIn) {
            e.preventDefault();
        } else {
            navigate('/collections');
        }
    };

    return (
        <nav className="navbar custom-navbar">
            <div className="container d-flex justify-content-between align-items-center">
                <Link className="navbar-brand" to="/">similto</Link>
                <div className="navbar-links d-flex align-items-center">
                    <Link className="nav-link" to="/">Home</Link>
                    <Link className="nav-link" to="/about">About</Link>
                    <Link className="nav-link" to="/contact">Contact</Link>
                    <div
                        className="nav-link-wrapper"
                        onMouseEnter={() => !isLoggedIn && setShowTooltip(true)}
                        onMouseLeave={() => setShowTooltip(false)}
                    >
                        <Link
                            className={`nav-link ${isLoggedIn ? '' : 'disabled-link'}`}
                            to={isLoggedIn ? '/collections' : '#'}
                            onClick={handleMyCollectionsClick}
                        >
                            My Collections
                        </Link>
                        {!isLoggedIn && showTooltip && (
                            <div className="tooltip">
                                Login to interact with your collections
                            </div>
                        )}
                    </div>
                    <div className="google-login-wrapper">
                        {!isLoggedIn ? (
                            <GoogleLogin onLoginSuccess={handleGoogleLogin} />
                        ) : (
                            <GoogleLogout onLogout={handleGoogleLogout} />
                        )}
                    </div>
                </div>
            </div>
        </nav>
    );
}

export default Navbar;
