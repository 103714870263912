import React, { useEffect } from "react";
import axios from "axios";

const backendAppUrl = process.env.REACT_APP_BACKEND_API_URL;
const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

const GoogleLogin = ({ onLoginSuccess }) => {
    useEffect(() => {
        window.google.accounts.id.disableAutoSelect();

        const handleCredentialResponse = (response) => {
            const token = response.credential;

            axios
                .post(backendAppUrl + "/auth/google", { token }, { withCredentials: true })
                .then((res) => {
                    console.log("Login success:", res.data);
                    onLoginSuccess(res.data);
                })
                .catch((err) => {
                    console.error("Login error:", err.response.data);
                });
        };

        const initializeGoogleSignIn = () => {
            // Check if the button is already rendered to avoid re-rendering
            if (!document.getElementById("googleSignInButton").hasChildNodes()) {
                window.google.accounts.id.initialize({
                    client_id: googleClientId,
                    callback: handleCredentialResponse,
                    auto_select: false,
                });
                window.google.accounts.id.renderButton(
                    document.getElementById("googleSignInButton"),
                    { theme: "outline", size: "large" }
                );
            }
        };

        initializeGoogleSignIn();
    }, [onLoginSuccess]); // Only depends on onLoginSuccess, will not re-render unnecessarily

    return <div id="googleSignInButton" style={{ width: '100%', height: '40px' }}></div>;
};

export default GoogleLogin;
