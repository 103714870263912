// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles.css';
import { LanguageCountryProvider } from './context/LanguageCountryContext';
import { AuthProvider } from './context/AuthContext';
import Collections from './pages/Collections';

function App() {
    return (
        <LanguageCountryProvider>
            <AuthProvider>
                <Router>
                    <Navbar />
                    <div style={{ minHeight: '80vh' }} className="main-content">
                        <Routes>
                            <Route path="/" element={<Home />} />
                            <Route path="/about" element={<About />} />
                            <Route path="/contact" element={<Contact />} />
                            <Route path="/collections" element={<Collections />} />
                        </Routes>
                    </div>
                    <Footer />
                </Router>
            </AuthProvider>
        </LanguageCountryProvider>
    );
}

export default App;
