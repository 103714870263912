import React from 'react';

function Contact() {
    return (
        <div>
            <h1>Contact Us</h1>
            <p>Contact us via email: contact@yourapp.com</p>
        </div>
    );
}

export default Contact;
