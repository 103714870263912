import React from 'react';

function Footer() {
    return (
        <footer className="custom-footer text-white text-center py-3 mt-5">
            <div className="container">
                <p className="mb-0">© 2024 similto. All rights reserved.</p>
            </div>
        </footer>
    );
}

export default Footer;
