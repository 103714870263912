// Home.js
import React, { useState, useContext, useEffect } from 'react';
import {
    fetchSimilarProducts,
    searchByImage,
    addItemToCollection,
    getUserCollections,
    createNewCollection
} from '../services/api';
import { LanguageCountryContext } from '../context/LanguageCountryContext';
import { FaCamera, FaRegBookmark  } from 'react-icons/fa';
import Modal from 'react-modal';
import GoogleLogin from '../components/GoogleLogin';
import { useAuth } from '../context/AuthContext';

function Home() {
    const [productUrl, setProductUrl] = useState('');
    const [imageFile, setImageFile] = useState(null);
    const [imagePreview, setImagePreview] = useState(null);
    const [userProduct, setUserProduct] = useState(null);
    const [results, setResults] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [collections, setCollections] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [signInModalIsOpen, setSignInModalIsOpen] = useState(false);
    const [newCollectionName, setNewCollectionName] = useState('');

    const { language, country } = useContext(LanguageCountryContext);
    const { login } = useAuth();

    useEffect(() => {
        if (signInModalIsOpen) {
            setTimeout(() => {
                window.google?.accounts.id.renderButton(
                    document.getElementById("googleSignInModalButton"),
                    { theme: "outline", size: "large" }
                );
            }, 100);
        }
    }, [signInModalIsOpen]);

    const sanitizeUrl = (url) => {
        try {
            const parsedUrl = new URL(url);
            parsedUrl.hash = ''; // Remove fragment
            return parsedUrl.toString();
        } catch (error) {
            console.error("Invalid URL:", url, error);
            return url; // Fallback to the original URL if parsing fails
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        try {
            const sanitizedUrl = sanitizeUrl(productUrl);

            let data;
            setResults(null);
            setUserProduct(null);

            if (imageFile) {
                data = await searchByImage(imageFile, country, language);
                setImageFile(null);
                setProductUrl('');
            } else if (sanitizedUrl.trim() !== '') {
                data = await fetchSimilarProducts(sanitizedUrl, country, language);
                setProductUrl('');
                setImageFile(null);
                setImagePreview(null);
            } else {
                console.error('No valid input provided for the search.');
                return;
            }

            setUserProduct(data.user_product || null);
            setResults(data.visual_matches || []);
        } catch (error) {
            console.error('Error fetching similar products:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setImageFile(file);
        setProductUrl('');
        setResults(null);
        setUserProduct(null);

        if (file) {
            const previewUrl = URL.createObjectURL(file);
            setImagePreview(previewUrl);
        }
    };

    const handleUrlChange = (e) => {
        setProductUrl(e.target.value);
        setImageFile(null);
        setImagePreview(null);
    };

    const calculateDiscount = (userPrice, similarPrice) => {
        const discount = ((userPrice - similarPrice) / userPrice) * 100;
        return discount.toFixed(2);
    };

    const arePricesComparable = (userProduct, product) => {
        return userProduct?.price?.extracted_value != null &&
            product?.price?.extracted_value != null &&
            userProduct.price.currency === product.price.currency;
    };

    const handleAddToCollectionClick = async (product) => {
        setSelectedProduct(product);
        try {
            const userCollections = await getUserCollections();
            setCollections(userCollections);
            setModalIsOpen(true);
        } catch (error) {
            if (error.response && error.response.status === 401) {
                setSignInModalIsOpen(true);
            } else {
                console.error('Error fetching collections:', error);
            }
        }
    };

    const handleCollectionSelect = async (collectionId) => {
        if (selectedProduct) {
            await addItemToCollection(collectionId, selectedProduct);
            setModalIsOpen(false);
            setSelectedProduct(null);
        }
    };

    const handleCreateNewCollection = async () => {
        const newCollection = await createNewCollection(newCollectionName);
        setCollections([...collections, newCollection]);
        setNewCollectionName('');
    };

    return (
        <div className="container">
            <div className="text-center my-5">
                <h1 className="display-4">similto</h1>
                <p className="lead">Paste any link below or upload an image and find the dittos!</p>
            </div>

            <form onSubmit={handleSubmit} className="mb-5 search-form">
                <div className="input-group">
                    <input
                        type="text"
                        value={productUrl}
                        onChange={handleUrlChange}
                        placeholder="Enter product URL"
                        className="form-control"
                    />
                    <label className="btn btn-secondary" htmlFor="image-upload">
                        <FaCamera />
                    </label>
                    <input
                        type="file"
                        id="image-upload"
                        accept="image/*"
                        style={{ display: 'none' }}
                        onChange={handleFileChange}
                    />
                    <button type="submit" className="btn btn-primary">Search</button>
                </div>
            </form>

            {isLoading && (
                <div className="text-center mb-4">
                    <div className="spinner"></div>
                    <p>Loading, please wait...</p>
                </div>
            )}

            {imagePreview && (
                <div className="user-product mb-4">
                    <div className="list-group-item clickable-product">
                        <img src={imagePreview} alt="Selected Preview" className="img-thumbnail me-3" />
                        <div className="product-details">
                            <h5>Selected Image Preview</h5>
                            <p className="product-source">Source: Local File</p>
                        </div>
                    </div>
                    <hr />
                </div>
            )}

            {userProduct && (
                <div className="user-product mb-4">
                    <a href={userProduct.link} target="_blank" rel="noopener noreferrer" className="list-group-item clickable-product">
                        <img src={userProduct.thumbnail} alt={userProduct.title} className="img-thumbnail me-3" />
                        <div className="product-details">
                            <h5>{userProduct.title}</h5>
                            <p className="product-source">Source: {userProduct.source}</p>
                            {userProduct.price && (
                                <p className="product-price">Price: {userProduct.price.currency} {userProduct.price.extracted_value}</p>
                            )}
                        </div>
                    </a>
                    <hr />
                </div>
            )}

            {results && (
                <div className="result-container">
                    <ul className="list-group">
                        {results.map((product, index) => {
                            const isComparable = arePricesComparable(userProduct, product);
                            const isCheaper = isComparable && product.price.extracted_value < userProduct.price.extracted_value;
                            const discountRate = isCheaper ? calculateDiscount(userProduct.price.extracted_value, product.price.extracted_value) : null;

                            return (
                                <li key={index} className="list-group-item clickable-product">
                                    <a href={product.link} target="_blank" rel="noopener noreferrer" className="product-link">
                                        <img src={product.thumbnail} alt={product.title} className="img-thumbnail me-3" />
                                        <div className="product-details">
                                            <h5>{product.title}</h5>
                                            <p className="product-source">Source: {product.source}</p>
                                            {product.price && (
                                                <p className="product-price">Price: {product.price.currency} {product.price.extracted_value}</p>
                                            )}
                                            {isCheaper && (
                                                <p className="discount-rate text-success">Discount: {discountRate}% off!</p>
                                            )}
                                        </div>
                                    </a>
                                    <button onClick={(e) => { e.stopPropagation(); handleAddToCollectionClick(product); }} className="btn btn-link">
                                        <FaRegBookmark />
                                    </button>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            )}

            <Modal isOpen={modalIsOpen} onRequestClose={() => setModalIsOpen(false)} className="collection-modal" overlayClassName="collection-overlay">
                <h2>Select Collection</h2>
                <ul className="collection-list">
                    {collections.map((collection) => (
                        <li key={collection.id} className="collection-item">
                            <button onClick={() => handleCollectionSelect(collection.id)} className="collection-button">
                                {collection.name}
                            </button>
                        </li>
                    ))}
                </ul>
                <div className="new-collection">
                    <input type="text" value={newCollectionName} onChange={(e) => setNewCollectionName(e.target.value)} placeholder="New Collection Name" className="collection-input" />
                    <button onClick={handleCreateNewCollection} className="create-collection-button">Create Collection</button>
                </div>
                <button onClick={() => setModalIsOpen(false)} className="close-modal-button">Close</button>
            </Modal>

            <Modal isOpen={signInModalIsOpen} onRequestClose={() => setSignInModalIsOpen(false)} className="sign-in-modal" overlayClassName="collection-overlay">
                <h2>Sign In Required</h2>
                <p>You need to sign in to save items to your collection.</p>
                <div id="googleSignInModalButton" className="center-content" style={{margin: "20px 0"}}>
                    <GoogleLogin
                        onLoginSuccess={() => {
                            login();             // Log the user in
                            setSignInModalIsOpen(false);  // Close the modal
                        }}
                    />
                </div>
                <button
                    onClick={() => setSignInModalIsOpen(false)}
                    className="modal-button close-modal-button" // Apply classes here
                >
                    Close
                </button>
            </Modal>
        </div>
    );
}

export default Home;
