import React, { useState, useEffect } from 'react';
import { getUserCollections } from '../services/api';

function Collections() {
    const [collections, setCollections] = useState([]);
    const [error, setError] = useState('');

    useEffect(() => {
        async function fetchCollections() {
            try {
                const userCollections = await getUserCollections();
                setCollections(userCollections);
            } catch (err) {
                setError('Failed to load collections');
            }
        }
        fetchCollections();
    }, []);

    return (
        <div className="container">
            <h1>My Collections</h1>
            {error && <p className="text-danger">{error}</p>}
            <div className="collections-list">
                {collections.map((collection) => (
                    <div key={collection.id} className="collection-card">
                        <h2 className="collection-title">{collection.name}</h2>
                        <div className="collection-items">
                            {collection.items.length > 0 ? (
                                collection.items.map((item) => (
                                    <a
                                        key={item.id}
                                        href={item.link}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="collection-item-link"
                                    >
                                        <img
                                            src={item.thumbnail}
                                            alt={item.title}
                                            className="collection-item-thumbnail"
                                        />
                                    </a>
                                ))
                            ) : (
                                <p className="no-items-message">You don't have any products in this collection.</p>
                            )}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Collections;
